import { useForm, useFormPersist, UseFormReturn } from '@graphcommerce/ecommerce-ui'
import { RetailStore } from '@graphcommerce/graphql-mesh'
import { createContext, ReactNode, useContext } from 'react'

type FormValues = {
  search: string
  service: string
  selectedStore?: RetailStore
  selected?: string
}

type StoreLocatorFormContextType = UseFormReturn<FormValues>
const StoreLocatorFormContext = createContext<StoreLocatorFormContextType | undefined>(undefined)

export function useStoreLocatorForm() {
  const context = useContext(StoreLocatorFormContext)
  if (!context) throw Error('useStoreLocatorForm must be used within a StoreLocatorFormProvider')
  return context
}

function Persist() {
  const form = useStoreLocatorForm()
  useFormPersist({ form, name: 'storelocator', persist: ['service', 'selectedStore'] })
  return null
}

export function StoreLocatorFormProvider(props: { children: ReactNode }) {
  const { children } = props

  const form = useForm<FormValues>()

  return (
    <StoreLocatorFormContext.Provider value={form}>
      <Persist />
      {children}
    </StoreLocatorFormContext.Provider>
  )
}
