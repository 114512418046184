/// <reference types="@graphcommerce/next-ui/types" />

import {
  responsiveVal,
  breakpointVal,
  MuiButtonPill,
  MuiButtonResponsive,
  themeBaseDefaults,
  MuiSnackbar,
  MuiFabSizes,
  MuiChip,
  MuiButtonInline,
  MuiSlider,
  iconCustomerService,
  NextLink,
} from '@graphcommerce/next-ui'
import { createTheme, Theme, alpha, LinkProps } from '@mui/material'
import { Components, PaletteOptions } from '@mui/material/styles'
import DaIconChat from './Icons/DaIconChat.svg'
import { bodoniModa, sofiaPro } from './localFonts'

declare module '@mui/material/Button/Button' {
  interface ButtonPropsVariantOverrides {
    pill: true
    cutRight: true
    cutLeft: true
  }
}

const lightPalette: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#E4007A',
    contrastText: '#ffffff',
    light: '#FDECEC80',
  },
  secondary: {
    main: '#E4007A',
    contrastText: '#ffffff',
    light: '#ffcfe5',
  },
  background: {
    default: '#F7F7F7',
    paper: '#ffffff',
    image: '#ffffff',
  },
  divider: '#00000015',
  success: {
    main: '#01d26a',
  },
  action: {
    hoverOpacity: 0.16,
  },
  text: {
    primary: '#000000',
    secondary: '#707070',
    disabled: '#00000030',
  },
}

export const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1536,
    xl: 1644,
    xxl: 2560,
  },
}

const fontSize = (from: number, to: number) =>
  breakpointVal('fontSize', from, to, breakpoints.values)

// Create a theme instance.
const createThemeWithPalette = (palette: PaletteOptions) =>
  createTheme({
    palette,
    ...themeBaseDefaults,
    breakpoints,
    shape: { borderRadius: 4 },
    typography: {
      // @see docs typography.md
      fontFamily: sofiaPro.style.fontFamily,
      h1: {
        fontFamily: bodoniModa.style.fontFamily,
        ...fontSize(20, 30),
        fontWeight: 'normal',
        lineHeight: responsiveVal(24, 33),
      },
      h2: {
        ...fontSize(25, 35),
        fontWeight: 'normal',
        lineHeight: '42px',
      },
      h3: {
        ...fontSize(22, 30),
        fontWeight: 600,
        lineHeight: '37px',
      },
      h4: {
        ...fontSize(18, 25),
        fontWeight: 700,
        lineHeight: '30px',
      },
      h5: {
        ...fontSize(17, 20),
        fontWeight: 'normal',
        lineHeight: 1.55,
      },
      h6: {
        ...fontSize(17, 20),
        fontWeight: 'normal',
        lineHeight: 1.8,
      },
      subtitle1: {
        ...fontSize(16, 19),
        fontWeight: 'normal',
        lineHeight: 1.7,
      },
      fontWeightBold: 600,
      body1: {
        ...fontSize(16, 18),
        lineHeight: 1.7,
        fontWeight: 500,
      },
      subtitle2: {
        ...fontSize(14, 16),
        fontWeight: 700,
        lineHeight: 1.7,
      },
      body2: {
        ...fontSize(13, 15),
        lineHeight: 1.7,
        fontWeight: 500,
      },
      caption: {
        // https://web.dev/font-size/#how-the-lighthouse-font-size-audit-fails
        ...fontSize(12, 13),
      },
      button: {},
      overline: {
        // https://web.dev/font-size/#how-the-lighthouse-font-size-audit-fails
        ...fontSize(12, 14),
        fontWeight: 500,
        letterSpacing: 1,
        lineHeight: 1.2,
        textTransform: 'uppercase',
      },
    },
    spacings: {
      xxs: responsiveVal(10, 16),
      xs: responsiveVal(12, 20),
      sm: responsiveVal(14, 30),
      md: responsiveVal(16, 50),
      lg: responsiveVal(24, 80),
      xl: responsiveVal(40, 100),
      xxl: responsiveVal(80, 160),
    },
    page: {
      horizontal: responsiveVal(14, 30),
      vertical: responsiveVal(10, 30),
    },
    appShell: {
      headerHeightSm: '46px',
      headerHeightMd: responsiveVal(46, 100),
      appBarHeightMd: '80px',
      appBarInnerHeightMd: '46px',
    },
  })

// todo: move most of the styles to the graphcommerce library while still allowing for extensibility.
const createOverrides = (theme: Theme): Components => ({
  IconSvg: {
    overrides: [[iconCustomerService, DaIconChat]],
  },

  MuiCssBaseline: {
    styleOverrides: {
      body: {
        overflowY: 'scroll',
      },
      '::selection': { background: alpha(theme.palette.primary.main, 0.6) },
      '::-moz-selection': { background: alpha(theme.palette.primary.main, 0.6) },
      '#__next': {
        position: 'relative',
      },
      'picture img': {
        filter: 'brightness(1.03)',
        willChange: 'filter',
      },
      'body, input,textarea,select, button': {
        fontSynthesis: 'none',
        MozFontFeatureSettings: 'kern',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
      },
    },
  },

  MuiContainer: {
    defaultProps: { maxWidth: 'xl' },
    variants: [
      {
        props: { disableGutters: false },
        style: {
          paddingLeft: theme.page.horizontal,
          paddingRight: theme.page.horizontal,
          [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.page.horizontal,
            paddingRight: theme.page.horizontal,
          },
        },
      },
    ],
  },

  MuiInputBase: {
    styleOverrides: {
      root: {
        fontSize: '16px', // https://css-tricks.com/16px-or-larger-text-prevents-ios-form-zoom/
      },
    },
  },
  Money: {
    defaultProps: { formatOptions: { style: undefined } },
  },
  MuiButton: {
    defaultProps: { color: 'inherit' },
    variants: [
      ...MuiButtonResponsive,
      ...MuiButtonPill,
      ...MuiButtonInline,
      {
        props: { variant: 'contained', color: 'inherit' },
        style: { backgroundColor: theme.palette.background.paper },
      },
      {
        props: { variant: 'outlined' },
        style: {
          ...breakpointVal(
            'borderRadius',
            theme.shape.borderRadius * 2,
            theme.shape.borderRadius * 3,
            theme.breakpoints.values,
          ),
        },
      },
      {
        props: { variant: 'text' },
        style: { borderRadius: '99em' },
      },
      {
        props: { variant: 'inline' },
        style: { borderRadius: '99em' },
      },
      {
        props: { variant: 'cutRight' },
        style: {
          borderRadius: '100px 0 0 100px',
          textAlign: 'center',

          '& div > span': {
            fontWeight: 700,
          },
        },
      },
      {
        props: { variant: 'cutRight', color: 'primary' },
        style: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,

          '&:hover': {
            backgroundColor: theme.palette.primary.contrastText,
            color: theme.palette.primary.main,
          },
        },
      },
      {
        props: { variant: 'cutRight', size: 'small' },
        style: {
          lineHeight: '16px',
          padding: '12px 19px',
          fontSize: `${responsiveVal(15, 17)} !important`,
        },
      },
      {
        props: { variant: 'cutLeft' },
        style: {
          borderRadius: '0 100px 100px 0',
          textAlign: 'center',

          '& div > span': {
            fontWeight: 700,
          },
        },
      },
      {
        props: { variant: 'cutLeft', color: 'primary' },
        style: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,

          '&:hover': {
            backgroundColor: theme.palette.primary.contrastText,
            color: theme.palette.primary.main,
          },
        },
      },
      {
        props: { variant: 'cutLeft', size: 'small' },
        style: {
          lineHeight: '16px',
          padding: '12px 19px',
          fontSize: '17px!important',
        },
      },
    ],
  },

  MuiFab: {
    styleOverrides: {
      root: {
        '&.MuiFab-default': {
          backgroundColor: theme.palette.background.paper,
          '&:hover': {
            backgroundColor: theme.palette.background.paper,
          },
          color: theme.palette.text.primary,
        },
      },
      colorInherit: {
        backgroundColor: 'inherit',
        '&:hover, &:focus': {
          backgroundColor: 'inherit',
        },
        boxShadow: 'none',
      },
      extended: {
        fontWeight: 400,
        textTransform: 'none',
      },
    },

    variants: [...MuiFabSizes],
  },

  MuiTextField: {
    defaultProps: { color: 'secondary' },
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-root': {
          ...breakpointVal(
            'borderRadius',
            theme.shape.borderRadius * 1.5,
            theme.shape.borderRadius * 2,
            theme.breakpoints.values,
          ),
        },
      },
    },
  },

  MuiListItemIcon: {
    styleOverrides: {
      root: {
        color: theme.palette.text.primary,
      },
    },
  },

  MuiChip: {
    variants: [...MuiChip],
  },

  MuiCheckbox: {
    styleOverrides: {
      colorPrimary: {
        color: theme.palette.text.disabled,
        '&.Mui-checked': {
          color: theme.palette.primary.main,
        },
      },
      colorSecondary: {
        color: theme.palette.text.disabled,
        '&.Mui-checked': {
          color: theme.palette.secondary.main,
        },
      },
    },
  },

  MuiSwitch: {
    styleOverrides: {
      track: {
        '.Mui-colorPrimary + &': {
          backgroundColor: theme.palette.primary,
          borderRadius: '30px',
        },
        '.Mui-checked.Mui-colorPrimary + &': {
          opacity: 1,
          backgroundColor: theme.palette.primary,
          borderRadius: '30px',
        },
      },
      thumb: {
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.divider}`,
      },
    },
  },

  MuiSnackbar: { variants: [...MuiSnackbar] },

  MuiAvatar: {
    styleOverrides: {
      colorDefault: {
        backgroundColor: theme.palette.text.disabled,
      },
    },
  },

  MuiSlider: { variants: [...MuiSlider] },

  MuiCircularProgress: {
    defaultProps: {
      thickness: 2,
    },
  },

  // https://mui.com/material-ui/guides/routing/#global-theme-link
  // https://www.graphcommerce.org/docs/framework/links
  MuiLink: { defaultProps: { component: NextLink } as LinkProps },
  MuiButtonBase: { defaultProps: { LinkComponent: NextLink } },
})

export const lightTheme = createThemeWithPalette(lightPalette)
lightTheme.components = createOverrides(lightTheme)
