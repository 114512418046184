import { FramerNextPages } from '@graphcommerce/framer-next-pages'
import { GraphQLProvider } from '@graphcommerce/graphql'
import { GlobalHead } from '@graphcommerce/magento-store'
import { CssAndFramerMotionProvider, PageLoadIndicator } from '@graphcommerce/next-ui'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { AppProps } from 'next/app'
import { StoreLocatorFormProvider } from '../components/StoreLocator/StoreLocatorFormProvider'
import { ZendeskChatWidget } from '../components/Zendesk/ZendeskChatWidget'
import { lightTheme } from '../components/theme'
import { I18nProvider } from '../lib/i18n/I18nProvider'

export default function ThemedApp(props: AppProps) {
  const { router } = props
  const { locale = 'en' } = router

  return (
    <CssAndFramerMotionProvider>
      <I18nProvider key={locale} locale={locale}>
        <GraphQLProvider {...props}>
          <ThemeProvider theme={lightTheme}>
            <GlobalHead />
            <CssBaseline />
            <PageLoadIndicator />
            <StoreLocatorFormProvider>
              <FramerNextPages {...props} />
            </StoreLocatorFormProvider>
            <ZendeskChatWidget />
          </ThemeProvider>
        </GraphQLProvider>
      </I18nProvider>
    </CssAndFramerMotionProvider>
  )
}
