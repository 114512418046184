import { useTheme } from '@mui/material'
import Script from 'next/script'
import { useEffect } from 'react'

export const ZendeskChatWidget = () => {
  const { palette } = useTheme()

  useEffect(() => {
    const script = document.getElementById('ze-snippet')
    if (script) {
      script.onload = () => {
        // When the script is loaded, configure Zendesk Chat Widget
        // Initially hide the Zendesk Chat Widget since it's activated by the Fab button in "LayoutDefault.tsx"
        if (globalThis.zE) {
          globalThis.zE('webWidget', 'hide')
          globalThis.zE('webWidget', 'setLocale', 'nl')
        }

        // Configure colors for the Zendesk Chat Widget
        globalThis.zESettings = {
          webWidget: {
            color: {
              theme: palette.primary.main,
              launcher: palette.primary.main,
              launcherText: palette.primary.contrastText,
              button: palette.primary.main,
              resultLists: palette.text.primary,
              header: palette.primary.main,
              articleLinks: palette.primary.main,
            },
          },
        } as ZendeskSettings
      }
    }
  }, [palette])

  return (
    <Script
      id='ze-snippet'
      src='https://static.zdassets.com/ekr/snippet.js?key=401cb6d4-9690-4ab1-8910-4012a49ca2a4'
    />
  )
}
